import { connect } from "react-redux";
import LevelFilter from "./LevelFilter";

const mapDispatchToProps = (dispatch) => ({
  
})

const mapStateToProps = (state) => ({

});

export default connect(
  mapDispatchToProps, 
  mapStateToProps
)(LevelFilter);