import React, {useEffect, useState} from "react";
import {Spin, Table} from "antd";

import './ModalDrillDownGroups.scss';

const ModalDrillDown = ({
  getBenchmarkDrillDownForGroups,
  indicator,
  groups,
  periodStart,
  periodEnd,
  isFetching,
  settingsBenchmark,
  t,
}) => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(null);
  const [drillDownData, setDrillDownData] = useState(null);

  useEffect(() => {
    getBenchmarkDrillDownForGroups({
      batch: true,
      indicator,
      groups: groups,
      period_start: periodStart,
      period_end: periodEnd,
      calculation_types: ['average'],
    })
      .then((response) => {
        setDrillDownData(response.data);
      })
      .catch((e) => console.error(e))
  }, [])

  useEffect(() => {
    const columns = [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        render: (text) => <p>{text}</p>
      },
      {
        title: t('result'),
        dataIndex: 'current',
        key: 'current',
        width: '40%',
        render: (text) => <p>{text}</p>
      }
    ];

    setColumns(columns);
  }, [settingsBenchmark])

  useEffect(() => {
    if (!!!drillDownData)
      return;

    const current = drillDownData?.values?.current?.[indicator];
    if (!!!current)
      return;

    const data = [];
    for (let i = 0; i < current.groups?.length ?? 0; i++) {
      const sortData = {
        key: i,
        name: current.groups?.[i]?.name,
        result: current.groups?.[i]?.result,
        data: current.groups?.[i]?.full?.map(f => {
          return {
            name: f.orgName,
            current: f.result
          };
        }) ?? []
      }

      data.push(sortData);
    }

    console.log(`Setting data:`);
    console.log(data);

    setData(data);
  }, [drillDownData])

  return (
    <div className="modal__wrapper">
      {
        isFetching || data === null ? <Spin size={'large'} /> : 
        <>
          <div style={{fontSize: '1.2rem', marginBottom: '0.5em', width: '100%'}}>
            {drillDownData?.values?.current?.[indicator]?.question}
          </div>

          {!!data && data?.map(g => 
            (
              <div key={g.id} style={{marginTop: "1.5em", width: "100%"}}>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: ".5em", width: "100%"}}>
                  <p style={{fontWeight: "600", margin: 0}}>{g.name}</p>
                  <p style={{margin: 0}}>{g.result}</p>
                </div>

                <Table bordered columns={columns} dataSource={g.data} pagination={false} style={{ width: '100%' }} />
              </div>
            )
          )}
          
        </>
      }
    </div>

  )
};

export default ModalDrillDown;
