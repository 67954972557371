import CategoryLink from "./../CategoryLink";
import React from "react";
import { Spin } from "antd";

export default function CategoryLinks({ categories, parentKey, setParentKey, updating }) {
    if (updating)
        return (
            <div className="d-flex justify-content-center mt-5">
                <Spin />
            </div>
        );

    return (
        categories && categories.map(cat => (
            <CategoryLink activeCategoryKey={parentKey} category={cat} key={cat.webform_key} onClick={setParentKey} />
        ))
    );
}