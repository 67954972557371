import { Checkbox, Progress, Spin } from "antd";
import { CloseCircleOutlined, CloseOutlined, HistoryOutlined, MoreOutlined, RightOutlined, WarningOutlined } from "@ant-design/icons";
import iconChatText from "../../../../../../assets/images/svg/chat-text.svg";
import { isObject } from "lodash";
import profilePic from "../../../../../../assets/images/svg/people-man.svg";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";

import './Category.scss';

function CategoryElement({element, loadingSubmission, onClick, submission, t, user}) {
    const [contentDropdownValues, setContentDropdownValues] = useState(element.users ?? []);
    const [userListVisible, setUserListVisible] = useState(false);

    if (element.states.includes('invisible') || !!!element.is_visible)
        return null;

    const assignedUsers = element.users ?? [];
    const options = [];
    const permissions = user.data.permissions;
    const progress = submission?.progress?.elements?.find((e) => e.key === element.webform_key) ?? null;

    const errors = isObject(submission?.validation?.errors) ? Object.keys(submission?.validation?.errors).filter(x => (element?.webform_children || []).includes(x)) : [];
    const warnings = isObject(submission?.validation?.warnings) ? Object.keys(submission?.validation?.warnings).filter(x => (element?.webform_children || []).includes(x)) : [];

    const triggerDropdown = (e, value = true) => {
        e.stopPropagation();
        setUserListVisible(value);
    };

    return (
        <div className="containerLinkCollapse" onClick={() => onClick(element.webform_key)}>
            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <div className="title" style={{ width: "300px", marginRight: 30 }}>
                    {element.title}
                </div>
                <div className="content">
                    <div style={{ opacity: 0, width: 110, height: 0 }}>
                        <HistoryOutlined style={{ marginRight: 10, maxWidth: 200, opacity: 0 }}/>
                        Autosaved on 12-05-2020
                    </div>

                    {errors?.length > 0 ? (
                        <div style={{alignItems: "center", display: "flex", width: 150}}>
                            <CloseCircleOutlined style={{ color: "red", marginRight: "4px" }}/>
                            {errors.length} {t(errors.length > 1 ? "error_messages" : "error_message")}
                        </div>
                    ) : null}

                    {warnings?.length > 0 ? (
                        <div style={{ alignItems: "center", display: "flex", width: 150 }}>
                            <WarningOutlined style={{ color: "orange", marginRight: "4px" }}/>
                            {warnings.length} {t(warnings.length > 1 ? "warning_messages" : "warning_message")}
                        </div>
                    ) : null}

                    {loadingSubmission ? (<Spin/>) : (progress?.count_input > 0 && (
                        <div style={{ width: 125 }}>
                            <img alt="" src={iconChatText} style={{ marginRight: 10 }}/>
                            {progress.count_input}{" "}
                            {progress.count_input === 1 ? t("general:question") : t("general:questions")}{" "}
                            ({progress.count_required}<span style={{ color: "red" }}>*</span>)
                        </div>
                    ))}

                    {loadingSubmission ? (<Spin/>) : (progress?.count_input > 0 && (
                        <>
                            {progress.count_input ? (
                                <Progress
                                    percent={+parseFloat((100 / progress.count_input) * progress.filled).toFixed(1)}
                                    showInfo
                                    size="small"
                                    status="active"
                                    style={{ width: 150 }}/>
                            ) : (
                                <Progress percent={0} showInfo size="small" status="active" style={{ width: 150 }} />
                            )}
                        </>
                    ))}

                    {loadingSubmission ? (<Spin/>) : (progress?.count_input ?? 0) === 0 && (
                        <div style={{ width: 150 }}></div>
                    )}

                    <span style={{ color: "#21416f", marginLeft: -25 }}>
                        <RightOutlined color="red" />
                    </span>
                </div>
            </div>

            {permissions.can_assign_users_to_webform_question && (
                <div className="collapseHeaderSelect">
                    <div className="containerDropdown">
                        <img alt="" src={profilePic} style={{ marginRight: 6 }} />
                        {assignedUsers.length ? (
                            <div onClick={(e) => triggerDropdown(e)}>
                                {assignedUsers[0].username}{" "}
                                {assignedUsers.length > 1 ? `(+${assignedUsers.length - 1} ${t("more")})` : ""}
                            </div>
                        ) : (
                            <div onClick={(e) => triggerDropdown(e)} style={{ textDecoration: "underline" }}>
                                {t("add_users")}
                            </div>
                        )}
                        <MoreOutlined />
                    </div>

                    <div className="containerCheckbox" style={{display: userListVisible ? "block" : "none"}}>
                        <div className="helpElement" />
                        <div className="containerCheckbox-contant">
                            <div className="title">
                                {t("users_list")}{" "}
                                <span>({options.length} {t("users")})</span>
                                <CloseOutlined onClick={(e) => triggerDropdown(e, false)} />
                            </div>

                            <div>
                                <Checkbox.Group className="questionnaireCheckboxes" onChange={setContentDropdownValues} options={options} value={contentDropdownValues}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default withTranslation("questionnaire")(CategoryElement);