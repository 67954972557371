import "./LevelFilter.scss";
import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { withTranslation } from "react-i18next";

function LevelFilter(props) {
  const { levels, onLevelFilterChanged, popoverId, t } = props;

  const [activeFilters, setActiveFilters] = useState([]);

  useEffect(() => {
    onLevelFilterChanged(activeFilters);
  }, [activeFilters]);

  const filterChanged = (values) => {
    setActiveFilters(values);
  }

  const filterCleared = () => {
    setActiveFilters([]);
  }

  if (!!!levels)
    return (<></>);

  return (
    <div className={"levelFilter-wrapper"}>
      <div className={"levelFilter-sub-wrapper"}>
        <div className={"levelFilter-header"}>
          <span className={"levelFilter-title"}>{t('level')}</span>
          <div className={"levelFilter-subheader"}>
            {!!activeFilters.length && (
              <span className={"levelFilter-clear"} onClick={() => filterCleared()}>{t(`filter_clear`)}</span>
            )}
          </div>
        </div>
        <div className={"levelFilter-input"}>
          <Select 
            allowClear={true} 
            getPopupContainer={() => document.getElementById(popoverId)} 
            mode='multiple' 
            onChange={filterChanged}
            placeholder={t("select")}
            value={activeFilters}
          >
            {levels.map(l => (
              <Select.Option key={l.id} value={l.id}>
                {l.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}

export default withTranslation("benchmark")(LevelFilter);