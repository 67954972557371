import { CaretRightOutlined, CloseCircleOutlined, CloseOutlined, MoreOutlined, RightOutlined, WarningOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Progress, Spin } from "antd";
import iconChatText from "../../../../../../assets/images/svg/chat-text.svg";
import iconFolder from "../../../../../../assets/images/svg/icon-folder.svg";
import { isObject } from "lodash";
import profilePic from "../../../../../../assets/images/svg/people-man.svg";
import React, { useState } from "react";
import WebformElement from "../../WebformElement";
import { withTranslation } from "react-i18next";

import "./CategoryWithSubCategories.scss";

function CategoryWithSubCategories({element, loadingSubmission, onClick, t, submission, user}) {
    const [activePanelKey, setActivePanelKey] = useState(null);
    const [contentDropdownValues, setContentDropdownValues] = useState(element.users ?? []);
    const [userListVisible, setUserListVisible] = useState(false);

    if (element.states.includes('invisible') || !!!element.is_visible)
        return null;

    const visible_category_children = element.elements.filter(e => e.type === 'category_container' && !e.states.includes('invisible'));

    const assignedUsers = element.users ?? [];
    const options = [];
    const permissions = user.data.permissions;
    const progress = submission?.progress?.elements?.find((e) => e.key === element.webform_key) ?? null;

    let webform_children = [];
    element.elements.forEach(e => {
        if (e.webform_children?.length > 0)
            e.webform_children.forEach(c => webform_children.push(c));
    });

    const errors = isObject(submission?.validation?.errors) ? Object.keys(submission?.validation?.errors).filter(x => webform_children.includes(x)) : [];
    const warnings = isObject(submission?.validation?.warnings) ? Object.keys(submission?.validation?.warnings).filter(x => webform_children.includes(x)) : [];

    const triggerDropdown = (e, value = true) => {
        e.stopPropagation();
        setUserListVisible(value);
    };

    return (
        <>
            <Collapse 
                activeKey={activePanelKey}
                destroyInactivePanel 
                expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                onChange={(v) => setActivePanelKey(v)}>
                <Collapse.Panel
                    key={element.webform_key}
                    header={
                        <div className="collapseHeader">
                            <div className="collapseHeaderMain">
                                <div className="title">{element.title}</div>
                                <div className="contentСollapseHeader">
                                    {errors?.length > 0 ? (
                                        <div style={{alignItems: "center", display: "flex", width: 150}}>
                                            <CloseCircleOutlined style={{ color: "red", marginRight: "4px" }}/>
                                            {errors.length} {t(errors.length > 1 ? "error_messages" : "error_message")}
                                        </div>
                                    ) : null}

                                    {warnings?.length > 0 ? (
                                        <div style={{ alignItems: "center", display: "flex", width: 150 }}>
                                            <WarningOutlined style={{ color: "orange", marginRight: "4px" }}/>
                                            {warnings.length} {t(warnings.length > 1 ? "warning_messages" : "warning_message")}
                                        </div>
                                    ) : null}

                                    <div style={{ width: 110 }}>
                                        <img alt="" src={iconFolder} style={{ marginRight: 10 }}/>
                                        {visible_category_children.length}{" "}
                                        {visible_category_children.length === 1 ? t("group") : t("groups")}
                                    </div>

                                    {loadingSubmission ? (<Spin/>) : (progress?.count_input > 0 && (
                                        <div style={{ width: 130 }}>
                                            <img alt="" src={iconChatText} style={{ marginRight: 10 }}/>
                                            {progress?.count_input}{" "}
                                            {progress?.count_input === 1 ? t("general:question") : t("general:questions")}{" "}
                                            ({progress?.count_required}<span style={{ color: "red" }}>*</span>)
                                        </div>
                                    ))}

                                    {loadingSubmission ? (<Spin/>) : (progress?.count_input > 0 && (
                                            <Progress 
                                                percent={progress?.count ? +parseFloat((100 / progress?.count_input) * progress?.filled).toFixed(1) : 0}
                                                showInfo
                                                size="small"
                                                status="active"
                                                style={{ width: 150 }}/>
                                    ))}

                                    {loadingSubmission ? (<Spin/>) : (progress?.count_input === 0 && (<div style={{ width: 150 }}></div>))}

                                    <span style={{ color: "#21416f" }}>
                                        <RightOutlined color="red" />
                                    </span>
                                </div>
                            </div>

                            {permissions.can_assign_users_to_webform_question && (
                                <div className="collapseHeaderSelect">
                                    <div className="containerDropdown">
                                        <img alt="" src={profilePic} style={{ marginRight: 6 }}/>
                                        {assignedUsers.length ? (
                                            <div onClick={(e) => triggerDropdown(e)}>
                                                {assignedUsers[0].username}{" "}
                                                {assignedUsers.length > 1 ? `(+${assignedUsers.length - 1} ${t("more")})` : ""}
                                            </div>
                                        ) : (
                                            <div onClick={(e) => triggerDropdown(e)} style={{ textDecoration: "underline" }} >
                                                {t("add_users")}
                                            </div>
                                        )}
                                        <MoreOutlined />
                                    </div>
                                    <div className="containerCheckbox" style={{display: userListVisible ? "block" : "none"}}>
                                        <div className="helpElement" />
                                        <div className="containerCheckbox-contant">
                                            <div className="title">
                                                {t("users_list")}{" "}
                                                <span>({options.length} {t("users")})</span>
                                                <CloseOutlined onClick={(e) => triggerDropdown(e, false)}/>
                                            </div>
                                            <div>
                                                <Checkbox.Group className="questionnaireCheckboxes" onChange={setContentDropdownValues} options={options} value={contentDropdownValues}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }>

                    {element.elements.length && !element.elements[0].webform_key ? (
                        element.elements ? (
                            <div className="d-flex justify-content-center mt-3">
                                <Spin />
                            </div>
                        ) : (
                            <div>Element doesn't have children</div>
                        )
                    ) : (
                        <>
                            {element.elements.map((el) => {
                                return (<WebformElement element={el} key={el.webform_key} loadingSubmission={loadingSubmission} onClick={onClick} submission={submission} />);
                            })}
                        </>
                    )}
                </Collapse.Panel>
            </Collapse>
        </>
    );
}

export default withTranslation('questionnaire')(CategoryWithSubCategories);