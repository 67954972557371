import { api } from "../../../../services";
import BenchmarkTableEdit from "../../../../assets/images/svg/benchmarkTableEdit";
import { Checkbox, Input, InputNumber, message, Modal, Popover, Radio, Select, Slider, Spin, Tooltip } from "antd";
import { ModalComparisonGroup, SearchInput } from "../../../../components";
import { EditOutlined, SearchOutlined, PlusSquareOutlined, MinusSquareOutlined, DownOutlined } from "@ant-design/icons/lib/icons";
import React, { useEffect, useMemo, useState } from "react";
import { sortByField } from "../../utils";
import { useParams } from "react-router-dom";

import "./FilterBlock.scss";
import SegmentationFilters from "../SegmentationFilters";
import LevelFilter from "../LevelFilter";

const { Option } = Select;

const FilterBlock = ({
  allOrganization,
  appSettings,
  checkedList,
  comparisonGroupIds,
  comparisonGroups,
  generateReport,
  getActiveOrganization,
  getBenchmarkComparisonGroups,
  getBenchmarkOrganization,
  getFilteredOrganizationForBenchmark,
  handlerCheckedFilters,
  handlerOpenAllCollapse,
  handlerSetSpecialOrganisations,
  isSearchLoader,
  optionList,
  searchByAllOrganizations,
  searchOrganization,
  setComparisonGroupIds,
  steps,
  t,
  user,
  viewPage = false,
}) => {
  const { id } = useParams();

  const [activeFilters, setActiveFilters] = useState({levelFilters: [], segmentationFilters: []});
  const [activeOrganization, setActiveOrganization] = useState(null);
  const [comparisonGroupEditId, setComparisonGroupEditId] = useState(null);
  const [comparisonGroupsChecked, setComparisonGroupsChecked] = useState([]);
  const [defaultComparisonGroups, setDefaultComparisonGroups] = useState([]);
  const [indicatorFilterData, setIndicatorFilterData] = useState(null);
  const [individualOrganisationIndicatorData, setIndividualOrganisationIndicatorData] = useState([]);
  const [individualOrganizations, setIndividualOrganizations] = useState([]);  
  const [levelFilters, setLevelFilters] = useState(null);
  const [loadingIndividualOrganizations, setLoadingIndividualOrganizations] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [openAllCollapse, setOpenAllCollapse] = useState(false);
  const [organizationsInEditGroup, setOrganizationsInEditGroup] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [showApplyButton, setShowApplyButton] = useState(false);
  const [showApplyOrganizationButton, setShowApplyOrganizationButton] = useState(false);
  const [specialGroupsId, setSpecialGroupsId] = useState([]);
  const [visibleGroupPopover, setVisibleGroupPopover] = useState(false);
  const [visibleIndividualGroupPopover, setVisibleIndividualGroupPopover] = useState(false);
  const [visibleOrganizationPopover, setVisibleOrganizationPopover] = useState(false);
   
  const sortedOrganizations = allOrganization?.sort((x, y) => x.title.localeCompare(y.title));
  
  useEffect(() => {
    getBenchmarkOrganization();
  }, []);

  useEffect(() => {
    if (id) {
      api.questionnaires
        .getSegmentationElements(id)
        .then((response) => {
          const { status, data } = response;
          if (status === 200)
            setIndicatorFilterData(data.webform);
        })
        .catch((e) => console.error(e));

      api.benchmark.getBenchmarkLevels()
        .then((response) => {
          const { data: { terms } } = response;
          setLevelFilters(terms);
        })
        .catch((e) => console.error(e))
    }
  }, [id]);

  useEffect(() => {
    const defaultGroupNames = comparisonGroups.filter((item) => comparisonGroupIds.includes(item.id));
    setDefaultComparisonGroups(defaultGroupNames);
  }, [comparisonGroupIds, comparisonGroups]);

  async function onLevelFilterChanged(levelFilters) {
    setLoadingIndividualOrganizations(true);

    const updatedFilters = {
      ...activeFilters,
      levelFilters: levelFilters
    };

    setActiveFilters(updatedFilters);

    await getFilteredOrganizationForBenchmark(id, updatedFilters)
        .then((response) => {
          setIndividualOrganizations(response.data.values);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingIndividualOrganizations(false));
  }

  async function onIndividualSegmentationFiltersChanged(segmentationFilters) {
    setLoadingIndividualOrganizations(true);

    const updatedFilters = {
      ...activeFilters,
      segmentationFilters: segmentationFilters
    };

    setActiveFilters(updatedFilters);

    await getFilteredOrganizationForBenchmark(id, updatedFilters)
        .then((response) => {
          setIndividualOrganizations(response.data.values);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingIndividualOrganizations(false));
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalEdit(false);
    setOrganizationsInEditGroup(null);
  };

  useEffect(() => {
    const userActiveOrganisation = user?.data?.organisations.find((item) => item.id === user?.data?.organisation);
    setActiveOrganization(userActiveOrganisation);
  }, []);

  const handlerChangeMyOrganization = (e) => {
    const organization = user?.data?.organisations.find((item) => item.id === e.target.value);

    setShowApplyOrganizationButton(true);
    getActiveOrganization(organization);
    setActiveOrganization(organization);
  };

  const handlerSelectComparisonGroups = (ids) => {
    if (steps === 4) 
      setShowApplyButton(true);

    setComparisonGroupsChecked(comparisonGroups.filter((item) => item.id in ids));
    setComparisonGroupIds(ids);
  };

  const organizations = user?.data?.organisations
    ?.sort(sortByField("title"))
    ?.map((item) => (
      <Radio value={item.id} key={item.id}>
        {item.title}
      </Radio>
    ));

  const contentPopoverOrganization = (
    <div className={"popover__organization"}>
      <div className={"radio__groups"}>
        <div className={"radio__group"}>
          <p className={"radio__title"}>{t("my_organisations")}</p>
          <Radio.Group
            value={activeOrganization?.id}
            onChange={handlerChangeMyOrganization}
          >
            {organizations}
          </Radio.Group>
          {steps === 4 && showApplyOrganizationButton && (
            <button
              className={"group__action apply__button organization__apply"}
              onClick={() => {
                setShowApplyOrganizationButton(false);
                setVisibleOrganizationPopover(false);
                generateReport();
              }}
            >
              {t("apply")}
            </button>
          )}
        </div>
        <div className={"radio__group"}>
          <p className={"radio__title"}>{t("other_organisations")}</p>
          <Radio.Group onChange={handlerChangeMyOrganization}>
            {Array.isArray(allOrganization)
              ? allOrganization?.sort((x, y) => x.title.localeCompare(y.title))?.map((item) => (
                  <Radio value={item.id} key={item.id}>
                    {item.title}
                  </Radio>
                ))
              : allOrganization &&
                Object.values(allOrganization)
                  ?.sort((x, y) => x.title.localeCompare(y.title))
                  ?.map((item) => (
                    <Radio value={item.id} key={item.id}>
                      {item.title}
                    </Radio>
                  ))}
          </Radio.Group>
        </div>
      </div>
    </div>
  );

  const deleteComparisonGroup = (id) => {
    api.benchmark
      .deleteComparisonGroup(id)
      .then((response) => {
        const { status } = response;
        if (status < 300)
          message.success(t("delete_comparison_group"));
      })
      .finally(() => {
        getBenchmarkComparisonGroups();

        setComparisonGroupsChecked(comparisonGroupsChecked.filter((item) => item.id != id));
        setDefaultComparisonGroups(defaultComparisonGroups.filter((item) => item.id != id));
        setComparisonGroupIds(comparisonGroupIds.filter((item) => item != id));

        setVisibleGroupPopover(false);
      });
  };

  const contentPopoverGroup = (
    <div className={"popover__group"}>
      <div className={"group__block"}>
        <div className={"group__item"}>
          <p className={"group__title"}>{t("saved_comparison_groups")}</p>
          <div className={"group__checkboxes"}>
            <Checkbox.Group value={comparisonGroupIds} onChange={handlerSelectComparisonGroups}>
              {comparisonGroups?.sort((x, y) => x.name.localeCompare(y.name))?.map((item) => (
                <div className={"group__radio--item"} key={item.id}>
                  <Checkbox key={item.id} value={item.id}>{item.name}</Checkbox>
                  <div className={"group__icons"}>
                    {item.personal_group && (
                      <>
                        <EditOutlined
                          onClick={() => {
                            setModalVisible(true);
                            setModalEdit(item.name);
                            setOrganizationsInEditGroup(item.organizations);
                            setVisibleGroupPopover(false);
                            setComparisonGroupEditId(item.id);
                          }}
                        />
                        <div
                          className={"delete-group"}
                          onClick={() => deleteComparisonGroup(item.id)}
                        />
                      </>
                    )}
                  </div>
                </div>
              ))}
            </Checkbox.Group>
          </div>
        </div>
        <div className={"group__item"}>
          <p className={"group__title"}>{t("create_comparison_group")}</p>
          <button
            className={"group__action"}
            onClick={() => {
              setModalVisible(true);
              setModalEdit(false);
              setVisibleGroupPopover(false);
            }}
          >
            {t("add_new_group")}
          </button>
          {steps === 4 && showApplyButton && (
            <button
              className={"group__action apply__button"}
              onClick={() => {
                setShowApplyButton(false);
                setVisibleGroupPopover(false);
                generateReport();
              }}
            >
              {t("apply")}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  const contentPopoverFilter = (
    <div className={"popover__content"}>
      <p className={"popover__title"}>{t('show_statistics')}</p>
      <Checkbox.Group
        onChange={handlerCheckedFilters}
        options={optionList}
        value={checkedList}
      />
    </div>
  );

  const handlerCheckedSpecialGroup = (e) => {
    const currentGroupsId = [...specialGroupsId];
    const hasSpecialGroup = currentGroupsId?.find((item) => item === e.target.value);

    if (hasSpecialGroup) {
      const newArray = currentGroupsId.filter((item) => item !== e.target.value);
      setSpecialGroupsId(newArray);
    } 
    else {
      currentGroupsId.push(e.target.value);
      setSpecialGroupsId(currentGroupsId);
    }
  };

  const handlerApplySpecialOrganisation = () => {
    const specialOrganisations = [];
    const sortOutData = specialGroupsId?.forEach((item) =>
      allOrganization?.filter((value) => {
        if (value.id === item) 
          specialOrganisations.push(value);
      })
    );
    setVisibleIndividualGroupPopover(false);
    handlerSetSpecialOrganisations(specialOrganisations);
    message.success(`Filter ${t("apply")}!`);
  };

  const getOrganisations = () => {
    let organizations = individualOrganizations
      .filter(x => x.id != activeOrganization.id) // Skip own organisation
      .sort((x, y) => x.title.localeCompare(y.title)); // Sort by title

    if(searchValue)
      organizations = organizations?.filter((item) => item.title.search(new RegExp(searchValue, 'i')) !== -1);

    return organizations;
  };

  const contentPopoverSpecificOrganisation = (
    <div className={"popover__individual--group"}>
      <div className={"group__block"}>
        <div className={"group__item"}>
          <p className={"group__title"}>{t("individual_organizations_title")}</p>
          <p className={"group__subtitle"}>{t("individual_organizations_subtitle")}</p>
          <div className={"group__checkboxes"}>
            {loadingIndividualOrganizations ? <Spin size={'large'} style={{ padding: '20px' }} /> : (
              <Checkbox.Group value={specialGroupsId}>
                {getOrganisations()?.map((item) => (
                  <Tooltip key={item.id} placement="left" title={item.title} zIndex={20}>
                    <Checkbox
                      onChange={handlerCheckedSpecialGroup}
                      value={item.id}
                      key={item.id}>
                      {item.title}
                    </Checkbox>
                  </Tooltip>
                ))}
              </Checkbox.Group>
            )}
          </div>
          <button className={"action__button"} onClick={handlerApplySpecialOrganisation}>{t("apply")}</button>
        </div>
        <div className={"group__item"}>
          <p className={"group__title"}>{t("filters")}</p>
          <p className={"group__searchbar"}>
            <SearchInput
              onSearch={setSearchValue}
              realtime={true}
              text={t("search_in_organizations")}
              value={searchValue}
            />
          </p>

          <LevelFilter levels={levelFilters} onLevelFilterChanged={onLevelFilterChanged} popoverId={'individual_organisations_popover'} />
          <SegmentationFilters id={id} onActiveFiltersChanged={onIndividualSegmentationFiltersChanged} popoverId={"individual_organisations_popover"} segmentationFilters={indicatorFilterData} />
        </div>
      </div>
    </div>
  );

  const handlerSelectIndicatorData = (value, item) => {
    const currentData = individualOrganisationIndicatorData;
    const hasKey = individualOrganisationIndicatorData?.find(key => Object.keys(key)[0] === item.indicator);

    if (!hasKey) {
      const obj = {};
      obj[item.indicator] = value;
      currentData.push(obj);
    } 
    else {
      currentData.forEach(c => {
        if (Object.keys(c)[0] === item.indicator)
          c[item.indicator] = value;
      });
    }

    setIndividualOrganisationIndicatorData([...currentData]);
    searchByIndividualOrganizations(currentData);
  };

  const searchByIndividualOrganizations = (indicators = []) => {
    let newIndicatorsData = {};

    indicators.forEach(item => {
      for (let key in item)
        newIndicatorsData[`${key}`] = item[key];
    });

    const params = {
      site_id: appSettings.site_id,
      url: appSettings.calculation_url,
      q: '',
      indicators: newIndicatorsData,
      organisation_levels: [],
    }
    searchByAllOrganizations(params);
  }

  const handleVisibleChange = (visible) => setVisibleGroupPopover(visible);
  const handleVisibleIndividualGroup = (visible) => setVisibleIndividualGroupPopover(visible);
  const handleVisibleOrganizationChange = (visible) => setVisibleOrganizationPopover(visible);

  return (
    <div className={"report__organization"}>
      <Modal
        footer={null}
        onCancel={closeModal}
        open={modalVisible}
        style={{ top: 0, maxWidth: "100vw" }}
        title={modalEdit ? `${t("edit_group")} ${modalEdit}` : t("create_new_group")}
        width={"100%"}
      >
        <ModalComparisonGroup
          closeModal={closeModal}
          activeOrganization={activeOrganization}
          comparisonGroupEditId={comparisonGroupEditId}
          indicatorFilterData={indicatorFilterData}
          modalEdit={modalEdit}
          organizationsInEditGroup={organizationsInEditGroup}
          visible={modalVisible}
        />
      </Modal>
      {!viewPage && (
        <>
          <div className={"report__your-organization"}>
            <Popover
              content={contentPopoverOrganization}
              onOpenChange={handleVisibleOrganizationChange}
              open={visibleOrganizationPopover}
              placement="bottomLeft"
              trigger={"click"}
            >
              <div className={"report__organization--popover"}>
                <p>{activeOrganization?.title || t("your_organisation")}</p>
                <DownOutlined />
              </div>
            </Popover>
          </div>

          <div className={"report__organization-text"}>{t("compare_to")}</div>

          <div className={"report__your-organization"}>
            <Popover
              content={contentPopoverGroup}
              onOpenChange={handleVisibleChange}
              open={visibleGroupPopover}
              placement="bottomLeft"              
              trigger={"click"}
            >
              <div className={"report__organization--popover"}>
                <p>
                  {(comparisonGroupsChecked.length == 1 &&
                    comparisonGroupsChecked?.name) ||
                    (defaultComparisonGroups.length == 1 &&
                      defaultComparisonGroups?.name) ||
                    t("comparison_groups")}
                </p>
                <DownOutlined />
              </div>
            </Popover>
          </div>

          <div className={"report__your-organization"}>
            <Popover
              className={"popover__specific--group"}
              content={contentPopoverSpecificOrganisation}
              id={"individual_organisations_popover"}
              onOpenChange={handleVisibleIndividualGroup}
              open={visibleIndividualGroupPopover}
              placement="bottomLeft"
              trigger={"click"}              
            >
              <div className={"report__organization--popover"}>
                <p style={{ maxWidth: "300px" }}>
                  {t("select_individual_organizations")}
                </p>
                <DownOutlined />
              </div>
            </Popover>
          </div>
        </>
      )}

      <div className={"report__organization--filters"}>
        <div className={"filter-content"}>
          {steps === 4 &&
            (openAllCollapse ? (
              <div
                className={"filter-text"}
                onClick={() => {
                  setOpenAllCollapse(false);
                  handlerOpenAllCollapse(false);
                }}
              >
                <span>{t("сollapse_all_open")}</span>
                <MinusSquareOutlined />
              </div>
            ) : (
              <div
                className={"filter-text"}
                onClick={() => {
                  setOpenAllCollapse(true);
                  handlerOpenAllCollapse(true);
                }}
              >
                <span>{t("сollapse_all_close")}</span>
                <PlusSquareOutlined />
              </div>
            ))}
          <div className={"filter-popover"}>
            <Popover
              content={contentPopoverFilter}
              placement="bottom"
              trigger={"click"}
            >
              <div className={"filter-text"}>
                {t("table_settings")}
                <BenchmarkTableEdit />
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
